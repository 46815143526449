export const StorageKeys = {
  AuthTokenKey: 'accessToken',
  UserRoleKey: 'role',
  USER: 'UserData'
}


export enum LocalStorage {
  USER = 'UserData',
  // TOKEN = 'x-access-token',
  // CONFIGURATION = 'configuration',
  // setItem = "setItem"
}