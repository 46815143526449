import { ApiEndpoints } from './../config/api-endpoints.config';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _url: string = environment.apiUrls.api;

  constructor(private _http: HttpClient) { }

  getAllCustomers(data: any) {
    return this._http.post<any>(this._url + 'map/sites', data);
  }

  validateToken(data: any) {
    const ip = data.ip;
    const port = data.port;
    const route = data.route;
    const token = data.token;
    const url = `http://${ip}${port}${route}?token=${token}`;
    return this._http.get<any>(url);
  }

  exportToCSV(role: any): Observable<Blob> {
    let url = `${this._url}${ApiEndpoints.Export.Export}?roleName=${role}`;

    return this._http.get(url, {
      responseType: 'blob',
    });
  }

  exportToCSVOrder(role: any): Observable<Blob> {
    let url = `${this._url}${ApiEndpoints.Export.orderExport}?roleName=${role}`;
    return this._http.get(url, {
      responseType: 'blob',
    });
  }

  exportTransactionHistories(): Observable<Blob> {
    let url = `${this._url}${ApiEndpoints.Export.TransactionHistories}`;
    return this._http.get(url, {
      responseType: 'blob',
    });
  }

  exportUserTransaction(id: any): Observable<Blob> {
    let url = `${this._url}${ApiEndpoints.Dashboard.userTransaction + '/' + id}`;
    return this._http.get(url, {
      responseType: 'blob',
    });
  }
  //import excel
  uploadFiles(data: any) {
    const headers = {
      headers: new HttpHeaders({ isBackgroudCall: 'true' }),
    };
    return this._http.post<any>(this._url + ApiEndpoints, data, headers);
  }

  getOrderListByUserId(queryParams: any) {
    const type =
      queryParams?.role == 'user'
        ? 'userId'
        : queryParams?.role == 'employee'
          ? 'employeeId'
          : 'deliveredById';
    const role = queryParams?.userId;
    const limit = queryParams?.limit;
    const page = queryParams?.pageNo;
    const url = `${this._url}${ApiEndpoints.Order.OrderList}?${type}=${role}&limit=${limit}&pageNo=${page}`;
    return this._http.get<any>(url);
  }
  getUserList(queryParams: any) {
    const role = queryParams?.role;
    const limit = queryParams?.limit;
    const page = queryParams?.pageNo;
    const status = queryParams?.status;
    const search = queryParams?.search;
    const url = `${this._url}${ApiEndpoints.User.GetUsers}?roleName=${role}&status=${status}&limit=${limit}&pageNo=${page}&search=${search}`;
    return this._http.get<any>(url);
  }

  addRegions(data: any, id?: string) {
    if (id) {
      return this._http.put<any>(this._url + ApiEndpoints.Configurations.ServiceAbleRegions + '/' + id, data)
    } else {
      return this._http.post<any>(this._url + ApiEndpoints.Configurations.ServiceAbleRegions, data)
    }
  }

  getServiceAbleRegions(queryParams: any) {
    const limit = queryParams?.limit;
    const page = queryParams?.pageNo;
    const search = queryParams?.search;
    const url = `${this._url}${ApiEndpoints.Configurations.ServiceAbleRegions}?limit=${limit}&pageNo=${page}&search=${search}`
    return this._http.get<any>(url)
  }
  deleteRegion(userId: string) {
    return this._http.delete<any>(
      this._url + ApiEndpoints.Configurations.ServiceAbleRegions + '/' + userId
    );
  }
  getConfigFeeList(queryParams: any) {
    const name = queryParams?.name;
    const limit = queryParams?.limit;
    const page = queryParams?.pageNo;
    const status = queryParams?.status;
    const search = queryParams?.search;
    const url = `${this._url}${ApiEndpoints.Configurations.ConfigurationsFee}?type=${name}&status=${status}&limit=${limit}&pageNo=${page}&search=${search}`;
    return this._http.get<any>(url);
  }

  getNotificationsList(queryParams: any) {
    const limit = queryParams?.limit;
    const page = queryParams?.pageNo;
    const pageSize = queryParams?.pageSize;
    const url = `${this._url}${ApiEndpoints.AppNotifications.getNotification}?limit=${limit}&pageNo=${page}&pageSize=${pageSize}`;
    return this._http.get<any>(url);
  }

  getCustomersForAppNotification() {
    const url = `${this._url}${ApiEndpoints.AppNotifications.getUserList}`;
    return this._http.get<any>(url);
  }

  addNotification(data: any) {
    return this._http.post<any>(this._url + ApiEndpoints.AppNotifications.addNotification, data);
  }

  editNotification(userId: string, data: any) {
    return this._http.put<any>(
      this._url + ApiEndpoints.AppNotifications.editNotification + '/' + userId,
      data
    );
  }

  deleteNotifications(userId: string) {
    return this._http.delete<any>(
      this._url + ApiEndpoints.AppNotifications.deleteNotification + '/' + userId
    );
  }

  getCountList() {
    const url = `${this._url}${ApiEndpoints.Dashboard.count}`;
    return this._http.get<any>(url);
  }

  getTransaction(queryParams: any) {
    const limit = queryParams?.limit;
    const page = queryParams?.pageNo;
    const fromDate = queryParams?.fromDate;
    const toDate = queryParams?.toDate;
    const status = queryParams?.status;
    const search = queryParams?.search;
    const url = `${this._url}${ApiEndpoints.Dashboard.transaction}?status=${status}&limit=${limit}&pageNo=${page}&search=${search}&startDate=${fromDate}&endDate=${toDate}`;
    return this._http.get<any>(url);
  }

  getCategory(queryParams: any) {
    const year = queryParams?.year;
    const url = `${this._url}${ApiEndpoints.Dashboard.ProductCount}?year=${year}`;
    return this._http.get<any>(url);
  }

  getDashRevenue(queryParams: any) {
    const type = queryParams?.type;
    const fromDate = queryParams?.fromDate;
    const toDate = queryParams?.toDate
    const url = `${this._url}${ApiEndpoints.Dashboard.Revenue}?type=${type}&fromDate=${fromDate}&toDate=${toDate}`;
    return this._http.get<any>(url);
  }

  getItems(params: any) {
    const limit = params?.limit;
    const page = params?.pageNo;
    const search = params?.search
    const pageSize = params?.pageSize;
    const categoryName = params?.categoryName == ('all' || undefined) ? '' : params?.categoryName;
    const url = `${this._url}${ApiEndpoints.Items.getItems}?categoryName=${categoryName}&search=${search}&limit=${limit}&pageNo=${page}&pageSize=${pageSize}`;
    return this._http.get<any>(url);
  }

  getRequest(params: any) {
    const limit = params?.limit;
    const page = params?.pageNo;
    const pageSize = params?.pageSize;
    const url = `${this._url}${ApiEndpoints.Items.GetRequest}?limit=${limit}&pageNo=${page}&pageSize=${pageSize}`;
    return this._http.get<any>(url);
  }

  deleteItem(data: any) {
    return this._http.delete<any>(
      this._url + ApiEndpoints.Items.deleteItem + '/' + data
    );
  }

  copyItem(data: any) {
    return this._http.get<any>(
      this._url + ApiEndpoints.Items.CopyRequest + '/' + data
    );
  }

  getUserById(userId: string) {
    return this._http.get<any>(
      this._url + ApiEndpoints.User.GetUsers + '/' + userId
    );
  }

  getOrderById(userId: string) {
    return this._http.get<any>(
      this._url + ApiEndpoints.Order.OrderById + '/' + userId
    );
  }

  inviteCustomer(data: any) {
    return this._http.post<any>(this._url + ApiEndpoints.User.InviteUser, data);
  }
  deleteUser(userId: string) {
    return this._http.delete<any>(
      this._url + ApiEndpoints.User.addUser + '/' + userId
    );
  }

  deleteConfig(userId: string) {
    return this._http.delete<any>(
      this._url + ApiEndpoints.Configurations.DeleteConfig + '/' + userId
    );
  }



  updateUser(userId: string, data: any) {
    return this._http.put<any>(
      this._url + ApiEndpoints.User.addUser + '/' + userId,
      data
    );
  }
  editUser(userId: string, data: any) {
    return this._http.put<any>(
      this._url + ApiEndpoints.User.addUser + '/' + userId,
      data
    );
  }

  // editRegionfee(userId: string, data: any) {
  //   return this._http.put<any>(
  //     this._url + ApiEndpoints.Configurations.AddCongig + '/' + userId,
  //     data
  //   );
  // }
  editRegionfee(userId: string, data: any) {
    return this._http.post<any>(
      this._url + ApiEndpoints.Configurations.AddCongig,
      data
    );
  }

  addUser(data: any) {
    return this._http.post<any>(this._url + ApiEndpoints.User.addUser, data);
  }
  addConfigFee(data: any) {
    return this._http.post<any>(this._url + ApiEndpoints.Configurations.AddCongig, data);
  }

  // Music api

  getMusicList(queryParams: any) {
    const categoryName = queryParams?.categoryName;
    const limit = queryParams?.pageSize || 10;
    const page = queryParams?.pageNo;
    const pageSize = queryParams?.pageSize;
    const search = queryParams?.search;
    const language = queryParams?.language;
    const isFeatured = queryParams?.isFeatrued;
    const url = `${this._url}${ApiEndpoints}?limit=${limit}&pageNo=${page}&pageSize=${pageSize}&search=${search}&isFeatured=${isFeatured}&categoryName=${categoryName}&language=${language}`;
    return this._http.get<any>(url);
  }

  // Category api

  getCategoryListType(queryParams: any) {
    const type = queryParams?.type;
    const url = `${this._url}${ApiEndpoints.Category.GetCategoryList}?type=${type}`;
    return this._http.get<any>(url);
  }

  // video api end

  //Questions api start

  // Delete upload Image
  deleteUploadImage(img: any) {
    // return this._http.post<any>(this._url + ApiEndpoints.Settings.UserManagment.deleteUploadImage, img)
  }
  getCount() {
    return this._http.get<any>(this._url + ApiEndpoints.Dashboard.count);
  }
  getLog(queryParams: any) {
    const type = queryParams?.type;
    const limit = queryParams?.pageSize || 5;
    const page = queryParams?.pageNo;
    return this._http.get<any>(
      `${this._url}${ApiEndpoints.Dashboard.log}?type=${type}&pageSize=${limit}&pageNo=${page}`
    );
  }
  getNotification(userId: any) {
    return this._http.get<any>(
      `${this._url}${ApiEndpoints.Notification.getNotification}?userId=${userId}&isAdmin=${true}&read=${false}&serverPaging=false`
    );
  }

  deleteNotification(userId: any) {
    let data = {
      userId: userId
    }
    return this._http.post<any>(this._url + ApiEndpoints.Notification.deleteNotification, data);
  }
  createItems(data: any) {
    return this._http.post<any>(this._url + ApiEndpoints.Items.addItems, data);
  }

  createPromoCode(data: any) {
    return this._http.post<any>(
      this._url + ApiEndpoints.PromoCode.AddPromoCode,
      data
    );
  }

  updatePromoCode(payload: any, id: string) {
    return this._http.put<any>(
      this._url + ApiEndpoints.PromoCode.UpDatePromoCode + '/' + id,
      payload
    );
  }

  promoCodeList(id: string) {
    return this._http.get<any>(
      this._url + ApiEndpoints.PromoCode.PromoCodeList + '/' + id
    );
  }

  getPromoCode(params: any) {
    const search = params?.search;
    const limit = params?.limit;
    const page = params?.pageNo;
    const fromDate = params?.fromDate;
    const toDate = params?.toDate;
    const status = params?.status;
    const url = `${this._url}${ApiEndpoints.PromoCode.PromoCodeList}?limit=${limit}&status=${status}&search=${search}&pageNo=${page}&startDate=${fromDate}&endDate=${toDate}`;
    return this._http.get<any>(url);
  }

  getSupport(params: any) {
    // const search = params?.search;
    const limit = params?.limit;
    const page = params?.pageNo;
    // const fromDate = params?.fromDate;
    // const toDate = params?.toDate;
    // const status = params?.status;
    const url = `${this._url}${ApiEndpoints.Support.GetSupport}?limit=${limit}&pageNo=${page}`;
    return this._http.get<any>(url);
    // &status=${status}&search=${search}&pageNo=${page}&startDate=${fromDate}&endDate=${toDate}
  }

  getOrderList(params: any) {
    const search = params?.search
    const roleName = params?.role;
    const limit = params?.limit;
    const page = params?.pageNo;
    const fromDate = params?.fromDate;
    const toDate = params?.toDate;
    const status = params?.status;
    const url = `${this._url}${ApiEndpoints.Order.OrderList}?paymentStatus=${'paid'}&search=${search}&roleName=${roleName}&status=${status}&limit=${limit}&pageNo=${page}&startDate=${fromDate}&endDate=${toDate}`;
    return this._http.get<any>(url);
  }

  getAssignOrder() {
    const url = `${this._url}${ApiEndpoints.Order.OrderList}`;
    return this._http.get<any>(url);
  }

  changeStatus(data: { id: any; payload: any }) {
    return this._http.put<any>(
      this._url + ApiEndpoints.PromoCode.UpDatePromoCode + '/' + data.id,
      data.payload
    );
  }

  changeOrderStatus(data: { id: any; payload: any }) {
    return this._http.put<any>(
      this._url + ApiEndpoints.Order.UpdateOrder + '/' + data.id,
      data.payload
    );
  }

  changeRequestStatus(data: { id: any; payload: any }) {
    return this._http.put<any>(
      this._url + ApiEndpoints.Items.PutRequest + '/' + data.id,
      data.payload
    );
  }

  deletePromoCode(data: any) {
    return this._http.delete<any>(
      this._url + ApiEndpoints.PromoCode.deletePromoCode + '/' + data
    );
  }
  CategoryList(params: any) {
    // const businessId = params.businessId;
    // const roleName = params.roleName;
    // const isAvailable = params?.isAvailable;
    // const status = params?.status;
    const serverPaging = params.serverPaging;
    return this._http.get<any>(
      this._url +
      `${ApiEndpoints.CategoryList.categoryList}?serverPaging=${serverPaging}`
    );
  }
  itemList(id: string) {
    return this._http.get<any>(
      this._url + ApiEndpoints.Items.getItems + '/' + id
    );
  }
  updateItems(payload: any, id: string) {
    return this._http.put<any>(
      this._url + ApiEndpoints.Items.upDateItems + '/' + id,
      payload
    );
  }
  updateItemsRequest(payload: any, id: string) {
    return this._http.put<any>(
      this._url + ApiEndpoints.Items.upDateItemsRequest + '/' + id,
      payload
    );
  }
}
