import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authenticated = this.authService.isAuthenticated();
    const role = this.authService.getRole();
    const expectedRole = route.data['expectedRole'];

    if (authenticated && role === 'superAdmin' || 'subAdmin') {
      if (!expectedRole || role === expectedRole) {
        return true;
      }
      else {
        this.router.navigate(['/admin/orders']);
        return true;
      }
    }
    this.router.navigate(['/']);
    return false;
  }

}
