export const ApiEndpoints = {
  Auth: {
    Login: '/auths/signin',
    Verify: '/auths/verify',
    Resend: '/auths/resend',
    ForgotPassword: '/auths/forgotPassword',
    UpdatePassword: '/auths/updatePassword',
    ResetPassword: '/auths/resetPassword',
    Logout: '/auths/logout',
  },

  Notification: {
    getNotification: '/userNotifications',
    deleteNotification: '/userNotifications/mark/read'
  },
  AppNotifications: {
    getNotification: '/notifications',
    addNotification: '/notifications',
    editNotification: '/notifications',
    deleteNotification: '/notifications',
    getUserList: '/users/all/customers',
  },
  Category: {
    GetCategoryList: '/categories',
  },
  Export: {
    Export: '/users/export/excel',
    TransactionHistories: '/transactionHistories/export/excel',
    orderExport: '/orders/export/excel'
  },
  User: {
    addUser: '/users',
    GetUsers: '/users',
    GetUserDetail: '/users',
    InviteUser: '/invites',
  },

  Support: {
    GetSupport: '/supportTickets'
  },

  Order: {
    OrderList: '/orders',
    OrderById: '/orders',
    UpdateOrder: '/orders',
    DeleteOrder: '/orders',
    CreateOrder: '/orders',
  },

  PromoCode: {
    PromoCodeList: '/promoCodes',
    AddPromoCode: '/promoCodes',
    UpDatePromoCode: '/promoCodes',
    deletePromoCode: '/promoCodes',
  },
  Dashboard: {
    count: '/dashboards',
    transaction: '/transactionHistories',
    log: '/logs',
    ProductCount: '/dashboards/product/count',
    Revenue: '/dashboards/payment/revenue',
    userTransaction: '/transactionHistories/download'
  },
  Items: {
    addItems: '/products',
    upDateItems: '/products',
    upDateItemsRequest: '/productChanges',
    getItems: '/products',
    deleteItem: '/products',
    GetRequest: '/productChanges',
    PutRequest: '/productChanges',
    CopyRequest: '/products/deepLink'
  },
  CategoryList: {
    categoryList: '/categories',
  },
  Configurations: {
    ConfigurationsFee: '/configurations',
    ServiceAbleRegions: '/serviceAbleRegions',
    AddCongig: '/configurations',
    DeleteConfig: '/configurations'
  }
};
