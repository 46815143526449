
import { Injectable } from "@angular/core";
import { User } from "src/app/features/main/orders/models";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  set(key: string, data: any) {
    localStorage.setItem(key, data);
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  get user() :User | null {
    const user = localStorage.getItem('UserData');
    if(!user) return null
    return JSON.parse(user);
  }

}
