import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiEndpoints } from '../config';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	url: any = environment.apiUrls.api;
	constructor(private http: HttpClient) {}

	login(user: any): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		return this.http.post(this.url + ApiEndpoints.Auth.Login, user, httpOptions);
	}

	changePassword(data: any, id: string): Observable<any> {
		return this.http.put<any>(
			this.url + ApiEndpoints.Auth.UpdatePassword + '/' + id,
			data
		);
	}

	forgotPassword(data: any) {
		return this.http.post<any>(this.url + ApiEndpoints.Auth.ForgotPassword, data);
	}
	verifyOtp(data: any) {
		return this.http.post<any>(this.url + ApiEndpoints.Auth.Verify, data);
	}
	resetPassword(data: any, userId: string) {
		return this.http.put<any>(
			this.url + ApiEndpoints.Auth.ResetPassword + '/' + userId,
			data
		);
	}
	resendCode(data: any) {
		return this.http.post<any>(this.url + ApiEndpoints.Auth.Resend, data);
	}
	logout(userId: string) {
		return this.http.put<any>(this.url + ApiEndpoints.Auth.Logout + '/' + userId, '');
	}
}
