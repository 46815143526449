import { ToasterService } from './../services/toaster.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor(
        private toasterService: ToasterService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => this.handleResponse(event))
        );
    }


    private handleResponse(event: HttpEvent<any>) {
        if (event instanceof HttpResponse) {
            const message = event.body;
            if (message.isSuccess) {
                
                // if (message.message) this.toasterService.successToast(message.message);
            }else{
                if (message && message.error || message.message ) this.toasterService.errorToast(message.error || message.message);
            }
        }

        return event;
    }
}
