// import { ToasterService } from './toaster.service';
import { LocalStorageService } from './local-storage.service';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKeys } from '../config';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private ngZone: NgZone,
    private router: Router,
    private localStorageService:
      LocalStorageService) {
  }

  isAuthenticated(): boolean {
    const token = this.getAuthToken();
    if (token) return true;
    return false;
  }

  getAuthToken() {
    return localStorage.getItem(StorageKeys.AuthTokenKey)
  }
  getRole() {
    return localStorage.getItem(StorageKeys.UserRoleKey)
  }

  storeAuthToken(token: string) {
    localStorage.setItem(StorageKeys.AuthTokenKey, token);
  }

  removeToken() {
    localStorage.removeItem(StorageKeys.AuthTokenKey);
  }

  storeUserProfile(data: any) {
    localStorage.setItem(StorageKeys.USER, JSON.stringify(data));
  }

  storeRole(role:string) {
    localStorage.setItem(StorageKeys.UserRoleKey, role);
  }

  logout() {
    this.clearStorage()
    this.ngZone.run(() => this.router.navigate(['auth/sign-in'])
    )
  }

  // encrypt(value) {
  //   try {
  //     return Buffer.from(value).toString('base64');
  //   }
  //   catch (e) {
  //   }
  // }

  // decrypt(value) {
  //   return Buffer.from(value, 'base64').toString();
  // }

  storeTokens(token: string) {
    this.localStorageService.set(StorageKeys.AuthTokenKey, token);
  }

  clearStorage() {
    // this.localStorageService.remove(StorageKeys.AuthTokenKey);
    // this.localStorageService.remove(StorageKeys.UserRoleKey);
    // this.localStorageService.remove('userId');
    // this.localStorageService.remove('email');
    this.localStorageService.clear();
    
  }
}
