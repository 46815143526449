import { AuthService } from 'src/app/core/services';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';


@Injectable({ providedIn: 'root' })
export class UnauthGuard implements CanActivate {
  constructor(private _router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn = this.authService.isAuthenticated();
    const role = this.authService.getRole();
    if (isLoggedIn && role == 'superAdmin' || role == 'subAdmin') {
      this._router.navigate(['/admin']);
      return false
    }
    return true;
  }
}
